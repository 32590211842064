import { Row, Col, Icon, Cell, CellGroup } from 'vant';
import config from '@/config.js';
export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  methods: {
    isActive(path) {
      return this.$route.path === path;
    },

    trackButtonClick() {
      this.$trackEvent({
        action: 'download',
        category: 'Download',
        label: 'DownloadBottom'
      });
    }

  },

  data() {
    return {
      config: config
    };
  }

};